/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { CalendarPlus, Pencil, User } from "phosphor-react";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { readNotifications } from "../../../../../axios/requests/notifications";

interface Props {
  notifications: any;
  open: any;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  updated: boolean;
  naoLidas: any;
  setNaoLidas: any;
}

const HeaderNotificationsMenu: FC<Props> = ({
  notifications,
  open,
  setUpdated,
  naoLidas,
  updated,
  setNaoLidas,
}) => {
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!open) {
      setNaoLidas([]);
    }
  }, [open]);

  useEffect(() => {
    if (open == true) {
      const updated = notifications.map((notification: any) => {
        return notification.id_notificacao;
      });
      readNotifications(user.access_token, updated).then((res) => {
        if (res.status == 200) {
          setUpdated(true);
        }
      });
    }
  }, [open]);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px p-0"
      data-kt-menu="true"
    >
      {naoLidas.length > 0 ? (
        <p className="fw-bolder py-5 px-5 mb-none">
          Notficações por ler ({naoLidas.length})
        </p>
      ) : (
        <p className="text-muted py-5 px-5 mb-none">Sem notificações por ler</p>
      )}
      <div className="scroll-y mh-325px">
        {notifications.length
          ? notifications.map((item: any, index: any) =>
              item.titulo ? (
                <Link
                  key={`item_${item.id}_${index}`}
                  to={item.path}
                  role="button"
                >
                  <div
                    className={
                      "d-flex flex-column border-top border-secondary px-5 py-3"
                    }
                    style={{
                      backgroundColor: item.lida == "S" ? "" : "#F8F8F8",
                    }}
                  >
                    <p className="fw-bolder ">{item.titulo}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.subtitulo,
                      }}
                    ></p>
                    <p className="mb-none mt-2 fs-11">
                      {moment(item.data_envio).format("LLL")}
                    </p>
                  </div>
                </Link>
              ) : null
            )
          : null}
      </div>
    </div>
  );
};

export { HeaderNotificationsMenu };
