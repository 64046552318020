import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { HeaderNotificationsMenu, HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { useSelector } from "react-redux";
import { Bell } from "phosphor-react";
import { getUserNotifications } from "../../../../../axios/requests/notifications";

const toolbarButtonMarginClass = "ms-1 ms-lg-3";

const Topbar: FC = () => {
  const { config } = useLayout();
  const user = useSelector((state: any) => state.user);

  const [notifications, setNotifications] = useState<any>([]);
  const [naoLidas, setNaoLidas] = useState<any>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [image, setImage] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let interval: any;

  useEffect(() => {
    fetch(toAbsoluteUrl(`/uploads/users/${user.id}/profile.jpg`))
      .then(async (image) => {
        const imageBlob = await image.blob();
        if (imageBlob.type.startsWith("image")) {
          setImage(URL.createObjectURL(imageBlob));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function createInterval() {
    interval = setInterval(() => {
      getNotifications();
    }, 2 * 60 * 1000);
  }

  useEffect(() => {
    getNotifications();
    createInterval();
  }, []);

  function getNotifications() {
    getUserNotifications(user.access_token).then((res) => {
      if (res.status === 200) {
        if (res.data.length) {
          setNaoLidas(res.data.filter((item: any) => item.lida == 0));
        }
        setNotifications(res.data);
      }
    });
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* NOTIFICATIONS */}
      <div
        className={clsx(
          "d-flex align-items-center mx-lg-2",
          toolbarButtonMarginClass
        )}
      >
        {/* begin::Menu- wrapper */}
        <div
          className={clsx("btn btn-icon hover")}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
          onClick={() => {
            setOpen(!open);
            clearInterval(interval);
            createInterval();
          }}
        >
          <Bell size={30} />
          {naoLidas.length ? (
            !updated ? (
              <span className="label label-rounded label-notification"></span>
            ) : null
          ) : null}
        </div>

        <HeaderNotificationsMenu
          notifications={notifications}
          open={open}
          setUpdated={setUpdated}
          updated={updated}
          naoLidas={naoLidas}
          setNaoLidas={setNaoLidas}
        />
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        <div style={{ width: "40px", height: "40px" }}>
          {!isLoading ? (
            <>
              {!image ? (
                <div
                  className={clsx(
                    "cursor-pointer symbol text-white p-3 fw-bold",
                    user.gender == "M" ? "bg-male" : "bg-female"
                  )}
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="bottom"
                >
                  {user.initials}
                </div>
              ) : (
                <img
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="bottom"
                  width="40px"
                  height="40px"
                  src={image}
                  className="rounded pointer"
                />
              )}
            </>
          ) : null}
          <HeaderUserMenu />
        </div>
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export { Topbar };
