import clsx from "clsx";
import React from "react";

type Props = {
  type: string;
  state: any;
  stateUpdate: any;
  id?: any;
  title?: string;
  required?: boolean;
  placeholder?: string;
  param?: any;
  size?: string;
  disabled?: boolean;
  space?: any;
  topTag?: boolean;
  labelSize?: any;
  center?: boolean;
};

const Input: React.FC<Props> = ({
  type,
  state,
  stateUpdate,
  id,
  title,
  required,
  placeholder,
  param,
  size,
  disabled,
  space,
  topTag,
  labelSize,
  center,
}) => {
  return (
    <div className={clsx(size, space)}>
      {title ? (
        <label
          className={clsx(
            "col-form-label",
            !topTag && "mr-3",
            !topTag && labelSize,
            required && "required"
          )}
          htmlFor={id}
        >
          {title}
        </label>
      ) : null}
      <input
        id={id}
        type={type}
        required={required ? required : false}
        placeholder={placeholder}
        onChange={(e) => {
          if (param != null) {
            stateUpdate(e.target.value, param);
          } else {
            stateUpdate(e.target.value);
          }
        }}
        disabled={disabled}
        value={state ? state : ""}
        className={clsx("form-control", center ? "text-center" : "")}
      />
    </div>
  );
};

export default Input;
