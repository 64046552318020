import axios from "../../config";

export const getEvaluationPeriods = (
  access_token: string,
) => {
  return axios.get(
    "/evaluations/evaluation-periods",
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getEvaluatedCollaboratorsById = (access_token: string, start_period:any,end_period:any,type:any) => {
  return axios.get(
    `/evaluations/historic?inicio_periodo=${start_period}&fim_periodo=${end_period}&tipo=${type}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};



export const getFeedbackMethod = (access_token: string, user:any) => {
  return axios.get(
    `/evaluations/feedback-method?user=${user}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const createFeedbackMethod = (access_token: string, user:any, evaluator:any, evaluation:any,body:any) => {
  return axios.post(
    `/evaluations/feedback-method?user=${user}&avaliador=${evaluator}&avaliacao=${evaluation}`,body,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getCompetenciesForNeeds = (access_token: string, user:any, evaluator:any, evaluation:any) => {
  return axios.get(
    `/evaluations/competency-needs?user=${user}&avaliacao=${evaluation}&avaliador=${evaluator}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};


export const getTrainingNeeds = (access_token: string, user:any, evaluation:any) => {
  return axios.get(
    `/evaluations/training-needs?user=${user}&avaliacao=${evaluation}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};


export const createTrainningNeeds = (access_token: string, user:any, evaluation:any,body:any) => {
  return axios.post(
    `/evaluations/training-needs?user=${user}&avaliacao=${evaluation}`,body,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};



export const getFeedbackConfirmations = (access_token: string) => {
  return axios.get(
    `/evaluations/feedback-confirmations`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};


export const confirmFeedback = (access_token: string,evaluation:any, feedback:any,body:any) => {
  return axios.put(
    `/evaluations/feedback-confirmation?avaliacao=${evaluation}&feedback=${feedback}`,body,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const checkFeedbackConfirmation = (access_token: string,hash:any) => {
  return axios.get(
    `/evaluations/feedback-confirmation?hash=${hash}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};



