import clsx from "clsx";
import React from "react";

type Props = {
  id: any;
  state: any;
  stateUpdate: any;
  param?: any;
  title?: string;
  icon?: any;
  required?: boolean;
  placeholder?: string;
  size?: string;
  disabled?: boolean;
  space?: any;
  topTag?: boolean;
  labelSize?: any;
};

const TextArea: React.FC<Props> = ({
  id,
  state,
  stateUpdate,
  param,
  title,
  icon,
  required,
  placeholder,
  size,
  disabled,
  space,
  topTag,
  labelSize,
}) => {
  return (
    <div className={clsx(size, space)}>
      {title ? (
        <label
          className={clsx(
            "col-form-label",
            !topTag && "mr-3",
            !topTag && labelSize,
            required && "required"
          )}
          htmlFor={id}
        >
          {icon}
          {title}
        </label>
      ) : null}
      <textarea
        id={id}
        required={required}
        disabled={disabled}
        className={"form-control"}
        value={state || ""}
        placeholder={placeholder}
        onChange={(e) => {
          if (param) {
            stateUpdate(e.target.value, param);
          } else {
            stateUpdate(e.target.value);
          }
        }}
      />
    </div>
  );
};

export default TextArea;
