import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../components/_metronic/helpers";
import classes from "./Auth.module.css";
import { toast } from "react-toastify";
import InlineError from "../../components/UI/InlineError/InlineError";
import { recover } from "../../axios/requests/auth";
import clsx from "clsx";

const Recover: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  function sumbmitRecover(e: any) {
    e.preventDefault();
    recover(email)
      .then((res) => {
        if (res.status === 200) {
          toast("Foi enviada uma nova palavra-passe para o email.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            type: "success",
            theme: "colored",
          });
          navigate("/login");
        }
      })
      .catch(() => {
        setError("Não foi encontrado utilizador para o email indicado.");
        return;
      });
  }

  return (
    <div className={"col-12 d-flex flex-wrap"}>
      <div
        className={clsx(classes.left, "col-12 col-md-4 p-6")}
        style={{
          backgroundImage: `url("../../media/clients/impetus/login/bg.jpg")`,
        }}
      >
        <div className={classes.titles}>
          <h1>I'm Impetus</h1>
          <h2>Bem-vindo à plataforma digital do Grupo Impetus</h2>
        </div>
      </div>
      <div className={clsx(classes.right, "col-12 col-md-8")}>
        <form
          onSubmit={sumbmitRecover}
          className={clsx(classes.form, "col-12")}
        >
          <div className="col-8 col-sm-7 col-md-6 col-lg-6 col-xl-4">
            <div className="text-center mb-10">
              <img
                draggable={false}
                src={toAbsoluteUrl("/media/clients/impetus/logo/logo.png")}
                className={`${classes.logo}`}
              />
              <h1 className="text-dark mb-3 mt-6">Recuperar Palavra-Passe</h1>
              <h4 className="opacity-50">Introduza o seu email</h4>
            </div>

            {error ? (
              <p className="bg-danger fs-6 fw-bold text-white p-4 rounded mb-6">
                {error}
              </p>
            ) : null}
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-600 text-dark">Email</label>
              <input
                placeholder="Email"
                className="form-control form-control-lg form-control-solid"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required={true}
              />
            </div>
            <button
              className={`${classes.btnSubmit} btn btn-primary col-12`}
              type="submit"
            >
              Recuperar
            </button>
            {/* end submit */}
            {/* begin redirect */}
            <button
              className={`${classes.btnSubmit} btn btn-secondary col-12 mt-5`}
              onClick={() => {
                navigate("/login");
              }}
            >
              Voltar
            </button>
          </div>
        </form>
      </div>

      <a className={clsx(classes.footer, "p-6")} href="https://frejen.pt/">
        Desenvolvido por Frejen - Wise Solutions
      </a>
    </div>
  );
};

export default Recover;
