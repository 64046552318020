import React, { Suspense, useContext, useEffect } from "react";
import PublicRoutes from "./routes/Public";
import { useDispatch, useSelector } from "react-redux";

import { LayoutContext } from "./components/_metronic/layout/core/LayoutProvider";
import FullPageLoading from "./components/UI/Loading/FullPageLoading";
import { userActions } from "./store/user";
import { menusActions } from "./store/menus";

import { getInfo } from "./axios/requests/users";
import { getUserMenus } from "./axios/requests/menus";
import { AppDispatch } from "./store/store";

const App: React.FC = () => {
  const layoutProvider = useContext(LayoutContext);

  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: any) => state.user);
  const menus = useSelector((state: any) => state.menus.menus);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      layoutProvider.setAppLoading(false);
    } else if (!user.access_token) {
      dispatch(userActions.login({ access_token: storedToken }));
    }
  }, []);

  useEffect(() => {
    if (user.access_token && !user.id) {
      getInfo(user.access_token)
        .then((res: any) => {
          if (!layoutProvider.loading) {
            layoutProvider.setAppLoading(true);
          }
          dispatch(userActions.saveInfo(res.data));
        })
        .catch((err) => {
          localStorage.removeItem("token");
          layoutProvider.setAppLoading(false);
        });
    } else if (user.id) {
      getUserMenus(user.access_token).then((res: any) => {
        dispatch(menusActions.save({ menus: res.data }));
      });
    }
  }, [user]);

  useEffect(() => {
    if (menus) layoutProvider.setAppLoading(false);
  }, [menus]);

  return (
    <Suspense fallback={<div></div>}>
      {!layoutProvider.loading ? <PublicRoutes /> : <FullPageLoading />}
    </Suspense>
  );
};

export default App;
