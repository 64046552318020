import classes from "./FullPageLoading.module.css";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { SpinnerGap } from "phosphor-react";

const FullPageLoading: React.FC = () => {
  return (
    <div className={classes.mainDiv}>
      <img
        src={toAbsoluteUrl("/media/clients/impetus/logo/logo_full.png")}
        alt="logo"
        width="460px"
        className="mb-15"
      />
      <SpinnerGap className={classes.icon} size={50} strokeWidth="20px" />
    </div>
  );
};
export default FullPageLoading;
