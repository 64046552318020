import axios from "../config";

export const getUserNotifications = (access_token: string) => {
  return axios.get("/notifications/user", {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const readNotifications = (access_token: string,notifications:any) => {
  return axios.put("/notifications/user", notifications, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllSubmissions = (access_token: string,type:any, date:any, search:any, offset:any, order:any) => {
  return axios.get(`/notifications/sent?tipo=${type}&data=${date}&search=${search}&pagination=${offset[0]}&pagination=${offset[1]}&order=${order[0]}&order=${order[1]}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

