import React, { Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { WithChildren } from "../components/_metronic/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import Error404 from "../components/Errors/404";

//PAGES
const Home = React.lazy(() => import("../pages/Home/Home"));

const Maintenance_Users = React.lazy(
  () => import("../pages/Company/Employees/Employees")
);

const Maintenance_User = React.lazy(
  () => import("../pages/Company/Employees/Employee")
);

const Evaluations = React.lazy(
  () => import("../pages/Evaluations/Evaluations/Evaluations")
);

const EvaluationForm = React.lazy(
  () => import("../pages/Evaluations/Evaluations/EvaluationForm")
);

const SelfEvaluations = React.lazy(
  () => import("../pages/Evaluations/SelfEvaluation/SelfEvaluations")
);

const EvaluationSchedule = React.lazy(
  () => import("../pages/Evaluations/Schedule/Schedules")
);

const EvaluationScheduleDetail = React.lazy(
  () => import("../pages/Evaluations/Schedule/Schedule")
);

const EvaluationsMaintenance = React.lazy(
  () => import("../pages/Evaluations/Maintenance/Maintenance")
);

const EvaluationsFeedbackConfirmation = React.lazy(
  () => import("../pages/Evaluations/FeedbackConfirmation/FeedbackConfirmation")
);

const EvaluationsHistoric = React.lazy(
  () => import("../pages/Evaluations/History/History")
);

const EmployeeEvaluation = React.lazy(
  () => import("../pages/Evaluations/History/Cards/EmployeeEvaluation")
);

const HierarchicalLevels = React.lazy(
  () => import("../pages/Company/HierarchicalLevels/HierarchicalLevels")
);

const Configuration = React.lazy(
  () => import("../pages/Evaluations/Configuration/Configuration")
);

const Sectors = React.lazy(() => import("../pages/Company/Sectors/Sectors"));

const Logs = React.lazy(() => import("../pages/Company/Logs/Logs"));

const Profile = React.lazy(() => import("../pages/Profile/Profile"));

const Access = React.lazy(() => import("../pages/Company/Access/Access"));

const MenuGroups = React.lazy(
  () => import("../pages/Company/MenuGroups/MenuGroups")
);

//REPORTS
const EvaluationsReports = React.lazy(
  () => import("../pages/Evaluations/Reports/Reports")
);

const ReportsEvolution = React.lazy(
  () => import("../pages/Evaluations/Reports/AvaliacaoEvolucao")
);

const ReportsState = React.lazy(
  () => import("../pages/Evaluations/Reports/EstadoAvaliacoes")
);

const ReportsGlobal = React.lazy(
  () => import("../pages/Evaluations/Reports/Global")
);

const ReportsExportGlobal = React.lazy(
  () => import("../pages/Evaluations/Reports/ExportarGlobal")
);

const ReportsFeedback = React.lazy(
  () => import("../pages/Evaluations/Reports/ConfirmacaoFeedback")
);

const ReportsAggregated = React.lazy(
  () => import("../pages/Evaluations/Reports/ConsultaAgregada")
);

const ReportsTraining = React.lazy(
  () => import("../pages/Evaluations/Reports/PropostaFormacao")
);

//ROUTES
const Rotas = React.lazy(() => import("../pages/Company/Routes/Routes"));

const Notifications = React.lazy(
  () => import("../pages/Company/Notifications/Notifications")
);

//ROLES
const Roles = React.lazy(
  () => import("../pages/Company/RolesLevels/RolesLevels")
);

//TRAININGS
const TrainingsMaintenance = React.lazy(
  () => import("../pages/Trainings/Maintenance/Maintenance")
);

const PrivateRoutes: React.FC = () => {
  const menus = useSelector((state: any) => state.menus.menus);

  const menusCopy = useMemo(() => {
    if (menus) {
      let copy = [...menus];

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].children) {
          copy.push(...copy[i].children);
        }
      }

      return copy;
    } else {
      return [];
    }
  }, [menus]);

  function verifyAccess(id: number, Element: any) {
    return (
      <SuspensedView>
        {menusCopy.some((menu: any) => menu.id == id) ? (
          <Element />
        ) : (
          <Error404 />
        )}
      </SuspensedView>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />

      <Route
        path="/home"
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />
      <Route
        path="/profile"
        element={
          <SuspensedView>
            <Profile />
          </SuspensedView>
        }
      />

      <Route
        path="/company/employees"
        element={verifyAccess(2, Maintenance_Users)}
      />

      <Route
        path="/company/employees/:id"
        element={verifyAccess(2, Maintenance_User)}
      />

      <Route path="/evaluations/list" element={verifyAccess(4, Evaluations)} />

      <Route
        path="/evaluations/list/:id"
        element={verifyAccess(4, EvaluationForm)}
      />

      <Route
        path="/evaluations/self-evaluations"
        element={verifyAccess(17, SelfEvaluations)}
      />

      <Route
        path="/evaluations/self-evaluations/:id"
        element={verifyAccess(17, EvaluationForm)}
      />

      <Route
        path="/evaluations/schedules"
        element={verifyAccess(18, EvaluationSchedule)}
      />

      <Route
        path="/evaluations/schedules/:id"
        element={verifyAccess(18, EvaluationScheduleDetail)}
      />

      <Route
        path="/evaluations/reports"
        element={verifyAccess(5, EvaluationsReports)}
      />

      <Route
        path="/evaluations/reports/evolution"
        element={verifyAccess(10, ReportsEvolution)}
      />

      <Route
        path="/evaluations/reports/state"
        element={verifyAccess(12, ReportsState)}
      />

      <Route
        path="/evaluations/reports/global"
        element={verifyAccess(16, ReportsGlobal)}
      />

      <Route
        path="/evaluations/reports/export-global"
        element={verifyAccess(32, ReportsExportGlobal)}
      />

      <Route
        path="/evaluations/reports/feedback"
        element={verifyAccess(14, ReportsFeedback)}
      />

      <Route
        path="/evaluations/reports/aggregated"
        element={verifyAccess(11, ReportsAggregated)}
      />

      <Route
        path="/evaluations/reports/training"
        element={verifyAccess(15, ReportsTraining)}
      />

      <Route
        path="/evaluations/maintenance"
        element={verifyAccess(6, EvaluationsMaintenance)}
      />
      <Route
        path="/evaluations/historic"
        element={verifyAccess(7, EvaluationsHistoric)}
      />

      <Route
        path="/evaluations/feedback-confirmation"
        element={verifyAccess(19, EvaluationsFeedbackConfirmation)}
      />

      <Route
        path="/evaluations/historic/consult"
        element={verifyAccess(7, EmployeeEvaluation)}
      />

      <Route
        path="/company/levels"
        element={verifyAccess(22, HierarchicalLevels)}
      />
      <Route
        path="/evaluations/configurations"
        element={verifyAccess(23, Configuration)}
      />
      <Route path="/company/sectors" element={verifyAccess(8, Sectors)} />

      <Route path="/company/logs" element={verifyAccess(28, Logs)} />

      <Route path="/company/permissions" element={verifyAccess(24, Access)} />

      <Route
        path="/company/group-menus"
        element={verifyAccess(25, MenuGroups)}
      />

      <Route path="/company/routes" element={verifyAccess(26, Rotas)} />

      <Route
        path="/company/notifications"
        element={verifyAccess(27, Notifications)}
      />

      <Route path="/company/roles" element={verifyAccess(21, Roles)} />

      <Route
        path="/trainings/maintenance"
        element={verifyAccess(30, TrainingsMaintenance)}
      />

      <Route path="/login" element={<Navigate to="/home" />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const SuspensedView: React.FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default PrivateRoutes;
