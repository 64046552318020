import clsx from "clsx";

type Props = {
  id: any;
  state: any;
  stateUpdate: any;
  value: any;
  param?: any;
  title?: string;
  uncheckValue?: any;
  size?: any;
  space?: any;
  disable?: any;
};

const Checkbox: React.FC<Props> = ({
  id,
  state,
  stateUpdate,
  value,
  param,
  title,
  uncheckValue,
  size,
  space,
  disable,
}) => {
  return (
    <div className={clsx(size, space)}>
      <input
        id={id}
        className="form-check-input pointer"
        type="checkbox"
        onChange={(e) => {
          if (param) {
            if (e.target.checked) {
              stateUpdate(value, param);
            } else if (uncheckValue != null) {
              stateUpdate(uncheckValue, param);
            }
          } else {
            if (e.target.checked) {
              stateUpdate(value);
            } else if (uncheckValue != null) {
              stateUpdate(uncheckValue);
            }
          }
        }}
        checked={state == value}
        disabled={disable}
      />
      {title ? (
        <label htmlFor={id} className="col-form-label fw-600 fs-6 pointer ml-2">
          {title}
        </label>
      ) : null}
    </div>
  );
};

export default Checkbox;
