import clsx from "clsx";
import classes from "./LoadingButton.module.css";
import { SpinnerGap } from "phosphor-react";

type Props = {
  loading: boolean;
  title: any;
  submit?: boolean;
  onClick?: any;
  style?: string;
  size?: any;
  spacing?: string;
  disabled?: boolean;
  icon?: any;
};

const LoadingButton: React.FC<Props> = ({
  loading,
  title,
  submit,
  onClick,
  style,
  spacing,
  size,
  disabled,
  icon,
}) => {
  return (
    <button
      className={clsx(style ? style : "btn btn-primary", size, spacing)}
      type={submit ? "submit" : "button"}
      disabled={loading || disabled}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {loading && <SpinnerGap className={classes.icon} size={16} />}
      {icon}
      {title}
    </button>
  );
};

export default LoadingButton;
