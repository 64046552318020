import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    access_token: null,
    id: null,
    id_user_empresa: null,
    first_name: "",
    last_name: "",
    full_name: "",
    initials: "",
    email: "",
    phone: "",
    gender: "",
  },
  reducers: {
    login(state: any, action: PayloadAction<any>) {
      state.access_token = action.payload.access_token;
    },

    saveInfo(state: any, action: PayloadAction<any>) {
      state.id = action.payload.id ? action.payload.id : state.id;
      state.id_user_empresa = action.payload.id_user_empresa
        ? action.payload.id_user_empresa
        : state.id_user_empresa;
      state.first_name = action.payload.primeiro_nome
        ? action.payload.primeiro_nome
        : state.primeiro_nome;
      state.last_name = action.payload.ultimo_nome
        ? action.payload.ultimo_nome
        : state.ultimo_nome;
      state.email = action.payload.email ? action.payload.email : state.email;
      state.phone = action.payload.telemovel
        ? action.payload.telemovel
        : state.telemovel;
      state.initials = action.payload.iniciais
        ? action.payload.iniciais
        : state.iniciais;
      state.gender = action.payload.genero
        ? action.payload.genero
        : state.genero;
      state.full_name = action.payload.nome_completo
        ? action.payload.nome_completo
        : state.nome_completo;
    },

    logout(state: any) {
      state.access_token = null;
      state.id = null;
      state.id_user_empresa = null;
      state.first_name = "";
      state.last_name = "";
      state.full_name = "";
      state.email = "";
      state.phone = "";
      state.initials = "";
      state.gender = "";
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
