/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../../../store/user";
import { SignOut, User } from "phosphor-react";

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [hideLateralMenu, setHideLateralMenu] = useState(
    user.hide_lateral_menu
  );

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div
            className="text-white mr-3"
            style={{
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: user.gender == "M" ? "#0090c9d4" : "#ec9eab",
            }}
          >
            {user.initials}
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.first_name} {user.last_name}
            </div>
            <span className="fw-400 text-muted text-hover-primary fs-7">
              {user.email.length > 20
                ? `${user.email.substring(0, 20)}...`
                : user.email}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={"/profile"} className="menu-link px-3 text-dark">
          <User className="mr-2" /> O Meu Perfil
        </Link>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a
          onClick={() => {
            dispatch(userActions.logout());
            localStorage.removeItem("token");
          }}
          className="menu-link px-3 text-dark"
        >
          <SignOut className="mr-2" /> Terminar sessão
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
