import { useEffect, useState } from "react";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import { toAbsoluteUrl } from "../../../components/_metronic/helpers";
import { useSelector } from "react-redux";
import TextArea from "../../../components/UI/TextArea/TextArea";
import LoadingButton from "../../../components/UI/LoadingButton/LoadingButton";
import { useLocation } from "react-router-dom";
import {
  checkFeedbackConfirmation,
  confirmFeedback,
} from "../../../axios/requests/historic/historic";
import Loading from "../../../components/UI/Loading/Loading";
import { toast } from "react-toastify";
import { Check, CheckCircle, X } from "phosphor-react";

const FeedbackWithoutLogin: React.FC = () => {
  const token = useSelector((state: any) => state.user.access_token);
  const [isLoading, setIsLoading] = useState<any>(true);

  const [isSubmitted, setIsSubmitted] = useState<any>(false);

  const [hash, setHash] = useState<any>("");

  const location = useLocation().pathname;

  useEffect(() => {
    if (location) {
      let token_hash = location.replace("/feedback/", "");
      setHash(token_hash);
    }
  }, [location]);

  const [checked, setChecked] = useState<any>(false);

  const [observations, setObservations] = useState<any>("");

  const [feedback, setFeedback] = useState<any>(null);

  function getFeedbackConfirmation() {
    checkFeedbackConfirmation(token, hash).then((res) => {
      if (res.status === 200) {
        setFeedback(res.data);
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    if (hash) {
      getFeedbackConfirmation();
    }
  }, [hash]);

  function sendFeedback() {
    setIsLoading(true);
    let body = {
      observacoes: observations,
    };
    confirmFeedback(token, feedback.id_avaliacao, feedback.id, body)
      .then((res) => {
        toast(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          type: "success",
          theme: "colored",
        });
        setIsLoading(false);
        setIsSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          type: "error",
          theme: "colored",
        });
        setIsLoading(false);
      });
  }
  return (
    <div className="w-100 h-100 container bg-primary d-flex justify-content-center">
      <div className="col-3 mt-20">
        <div className="card bg-secondary p-10 col-12">
          <div className="card-header d-flex justify-content-center">
            <div className="card-title">
              <img
                alt="Logo"
                className="h-100px logo"
                src={toAbsoluteUrl("/media/clients/impetus/logo/logo.png")}
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {!isLoading &&
            feedback &&
            feedback.confirmou_feedback == 0 &&
            !isSubmitted ? (
              <div>
                <Checkbox
                  id={"check_submit"}
                  title={`Confirmo que recebi feedback de ${feedback.nome_avaliador}`}
                  state={checked}
                  stateUpdate={setChecked}
                  value={true}
                  uncheckValue={false}
                />
                {checked && (
                  <div className="d-flex flex-column">
                    <TextArea
                      id={"text_area"}
                      state={observations}
                      title="Observações relativas ao feedback recebido"
                      stateUpdate={setObservations}
                      space={"mt-5"}
                    />
                    <div className="d-flex justify-content-center mt-10">
                      <LoadingButton
                        loading={isLoading}
                        onClick={() => {
                          sendFeedback();
                        }}
                        title={"Confirmar"}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {!isLoading && !feedback && (
              <>
                <X weight="fill" fill="red" size={45} />
                <span className="bold fs-5 mt-5">
                  Não corresponde a uma confirmação de feedback
                </span>
              </>
            )}

            {!isLoading &&
            feedback &&
            feedback.confirmou_feedback == 1 &&
            !isSubmitted ? (
              <>
                <Check weight="fill" fill="green" size={45} />
                <span className="bold fs-5 mt-5 text-center">
                  O colaborador já confirmou a receção de feedback
                </span>
              </>
            ) : null}
            {isLoading && <Loading />}

            {!isLoading && isSubmitted && (
              <>
                {" "}
                <Check weight="fill" fill="green" size={45} />
                <span className="bold fs-4 mt-2">
                  Receção de Feedback confirmado
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackWithoutLogin;
